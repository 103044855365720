var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selectaria default-select",class:[
    { selectaria_error: _vm.isError, selectaria_success: !_vm.isError && _vm.selectVal } ]},[(_vm.label)?_c('div',{staticClass:"selectaria__label",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),_c('v-select',{staticClass:"selectaria__select",attrs:{"searchable":_vm.searchable,"options":_vm.items,"label":"label","deselectFromDropdown":_vm.multiple,"closeOnSelect":!_vm.multiple,"reduce":function (items) { return items.value; },"multiple":_vm.multiple,"placeholder":_vm.placeholder},on:{"input":_vm.change,"option:selected":function($event){return _vm.optionTriggered($event, true)},"option:deselecting":function($event){return _vm.optionTriggered($event, false)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
  var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.searchEmpty)+" ")]:[_vm._v(" Список пуст ")]]}},(_vm.multiple)?{key:"selected-option-container",fn:function(){return [_c('span',{staticClass:"placeholder-immitation"},[_vm._v(_vm._s(_vm.placeholder))])]},proxy:true}:null,{key:"list-header",fn:function(){return [_vm._t("list-header")]},proxy:true},(_vm.multiple)?{key:"option",fn:function(ref){
  var label = ref.label;
  var value = ref.value;
return [_c('Checkbox',{attrs:{"label":label,"value":_vm.selectVal.includes(value)}})]}}:null,{key:"open-indicator",fn:function(ref){
  var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('svg',{attrs:{"width":"13","height":"8","viewBox":"0 0 13 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.5 1.5L6.5 6.5L1.5 1.5","stroke":"#B2B4B4","stroke-linecap":"square"}})])])]}}],null,true),model:{value:(_vm.selectVal),callback:function ($$v) {_vm.selectVal=$$v},expression:"selectVal"}}),(_vm.isError)?_c('div',{staticClass:"selectaria__error",domProps:{"innerHTML":_vm._s(_vm.errorText)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }