import { bus } from "@/main";
export default {
  methods: {
    validate(uid) {
      let parent = this
      while (parent && parent._uid !== uid) {
        parent = parent.$parent
      }
      if (!this.disabled && this.rules && parent) {
        let error = this.rules.find((el) => typeof el === "string");
        if (error) {
          this.errorText = error;
          this.isError = true;
          bus.data.result.push(false);
        } else {
          (this.errorText = ""), (this.isError = false);
          bus.data.result.push(true);
        }
      } else {
         bus.data.result.push(true);
      }
    },
  },
}