<template>
  <div
    class="selectaria default-select"
    :class="[
      { selectaria_error: isError, selectaria_success: !isError && selectVal },
    ]"
  >
    <div class="selectaria__label" v-if="label" v-html="label"></div>
    <v-select
      :searchable="searchable"
      v-model="selectVal"
      class="selectaria__select"
      :options="items"
      label="label"
      :deselectFromDropdown="multiple"
      :closeOnSelect="!multiple"
      :reduce="(items) => items.value"
      :multiple="multiple"
      :placeholder="placeholder"
      @input="change"
      @option:selected="optionTriggered($event, true)"
      @option:deselecting="optionTriggered($event, false)"
    >
      <template v-slot:no-options="{ searching }">
        <template v-if="searching">
          {{ searchEmpty }}
        </template>
        <template v-else> Список пуст </template>
      </template>
      <template v-if="multiple" #selected-option-container="">
        <span class="placeholder-immitation">{{ placeholder }}</span>
      </template>
      <template #list-header>
        <slot name="list-header"></slot>
      </template>
      <template v-if="multiple" #option="{ label, value }">
        <Checkbox :label="label" :value="selectVal.includes(value)" />
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 1.5L6.5 6.5L1.5 1.5"
              stroke="#B2B4B4"
              stroke-linecap="square"
            />
          </svg>
        </span>
      </template>
    </v-select>
    <div class="selectaria__error" v-if="isError" v-html="errorText"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Checkbox from "./Checkbox.vue";
import validate from "@/mixins/validate";
import { bus } from "@/main";
export default {
  components: { Checkbox },
  name: "Select",
  mixins: [validate],
  props: {
    searchEmpty: { type: String, default: "Ничего не найдено" },
    value: Array,
    placeholder: String,
    items: Array,
    label: String,
    rules: Array,
    multiple: Boolean,
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errorText: "",
    isError: false,
  }),
  computed: {
    ...mapGetters(["Rules"]),
    selectVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.multiple) {
          this.$emit("input", val);
        } else {
          this.$emit("input", [val]);
        }
      },
    },
  },
  methods: {
    change() {
      this.$emit("change");
      this.validate(this.selectVal);
    },
    optionTriggered(arg, state) {
      this.$emit("optionTriggered", arg, state);
    },
  },
  created() {
    bus.$on("validate", this.validate);
  },
  destroyed() {
    bus.$off("validate", this.validate);
  },
};
</script>

<style lang="scss">
.default-select.selectaria {
  width: 100%;
  & .selectaria {
    .vs__dropdown-menu {
      padding-left: 0 !important;
    }
    &__label {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f1f1f;
      @media screen and (max-width: 767px) {
        margin-bottom: 12px;
      }
    }

    &__select {
      padding: 0 16px;
      width: 100%;
      height: 40px;
      max-width: 384px;
      background-color: #f8f8f8;
      border: 1px solid #d2d2d2;
      border-radius: 99px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #3c4242;
      display: block;
      transition: 0.3s;
      &:hover {
        border-color: #d0006f;
      }
      .vs__dropdown-toggle {
        height: 100%;
        border: none;
        padding: 0;
        .vs__search::placeholder {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #b2b4b4;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .vs__search:placeholder-shown {
          text-overflow: ellipsis;
        }
        .vs__search,
        .vs__search:focus {
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
        .vs__clear {
          display: none;
        }

        .vs__selected {
          margin: 0;
          position: static;
          opacity: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }

        .vs__selected > * {
          min-width: 0;
        }
      }

      .vs__dropdown-option {
        white-space: inherit;
      }

      .vs__selected-options {
        width: 100%;
        min-width: 0;
        align-items: center;
        flex-wrap: nowrap;
      }

      .vs__search {
        position: absolute;
        opacity: 0;
        width: 100%;
        z-index: 20;
      }

      .vs__search:focus {
        opacity: 1;
        background-color: #f8f8f8;
      }

      .vs__search:read-only {
        opacity: 1;
        background-color: transparent;
      }
    }
    &__error {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #cb4050;
    }
    &_error {
      & .selectaria__select {
        border-color: #cb4050;
        background-color: #faebed;
      }
    }
  }
}
.default-select {
  .placeholder-immitation {
    display: none;
    &:first-child {
      display: flex;
      align-items: center;
      line-height: 1.4;
      font-size: 1em;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #333333;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .vs__dropdown-option--selected {
    color: #830051 !important;
  }

  .vs__dropdown-option--deselect {
    background: none;
    color: inherit;
  }

  .vs__dropdown-option--highlight {
    background: none;
    color: inherit;
  }

  .vs__dropdown-option:hover {
    background: #830051;
    color: #fff !important;
  }
}
</style>
<style>
.default-select .vs__dropdown-option:hover .checkbox__label {
  color: #fff !important;
}
</style>